import { render, staticRenderFns } from "./ssw.vue?vue&type=template&id=0f8bf23c&scoped=true&"
var script = {}
import style0 from "./ssw.vue?vue&type=style&index=0&id=0f8bf23c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f8bf23c",
  null
  
)

export default component.exports