// @ts-ignore
import en from "vuetify/lib/locale/en";
// @ts-ignore
import ja from "vuetify/lib/locale/ja";
// @ts-ignore
import zhHans from "vuetify/lib/locale/zh-Hans";

export default {
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 600,
      md: 960,
      lg: 960,
    },
    scrollBarWidth: 24,
  },
  icons: {
    iconfont: "mdiSvg",
  },
  lang: {
    locales: {
      en,
      ja,
      "zh-Hans": zhHans,
    },
    current: "en",
  },
  rtl: false,
  theme: {
    default: "light",
    disable: false,
    themes: {
      light: {
        primary: "#4724b5",
        navy: "#150b36",
        gray: "#8798ad",
        pink: "#ff2f56",
        yellow: "#f6a005",
        secondary: "#6b45E2",
        accent: "#f6b205",
        error: "#f12238",
        warning: "#ffc107",
        info: "#2196f3",
        success: "#4caf50",
      },
      dark: {
        primary: "#4724b5",
        navy: "#150b36",
        gray: "#8798ad",
        pink: "#ff2f56",
        yellow: "#f6a005",
        secondary: "#6b45E2",
        accent: "#f6b205",
        error: "#f12238",
        warning: "#ffc107",
        info: "#2196f3",
        success: "#4caf50",
      },
    },
  },
};
