import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend, localize, configure } from 'vee-validate'

import { required, max, min, max_value, min_value, alpha_dash, email, numeric } from 'vee-validate/dist/rules'

extend('required', required)

extend('max', max)

extend('min', min)

extend('max_value', max_value)

extend('min_value', min_value)

extend('alpha_dash', alpha_dash)

extend('email', email)

extend('numeric', numeric)

const getLocale = (opt, locale) => {
  if (typeof opt === 'object' && opt.locale[locale]) {
    return opt.locale[locale]
  }
  return locale
}

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export default ({ app }) => {
  const nuxti18n = {"locale":{"zh-Hans":"zh_CN"}}
  if (nuxti18n && app.i18n) {
    const validatorLocale = getLocale(nuxti18n, app.i18n.locale)
    import(`vee-validate/dist/locale/${validatorLocale}.json`).then(dic => {
      localize(validatorLocale, dic)
    })

    const beforeLanguageSwitch = app.i18n.beforeLanguageSwitch
    app.i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {
      beforeLanguageSwitch(oldLocale, newLocale)
      const newValidatorLocale = getLocale(nuxti18n, newLocale)
      import(`vee-validate/dist/locale/${newValidatorLocale}.json`).then(dic => {
        localize(newValidatorLocale, dic)
      })
    }
  }
}
