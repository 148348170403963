// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/.pnpm/css-loader@5.2.7_webpack@4.46.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/.pnpm/css-loader@5.2.7_webpack@4.46.0/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/lp/sw/background.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ssw[data-v-0f8bf23c]{background-color:#f4f1e9;overflow:hidden}.Ssw__Main[data-v-0f8bf23c]{margin-top:6.25rem;padding:0 3rem!important}@media(max-width:960px){.Ssw__Main[data-v-0f8bf23c]{margin-top:4.375rem;padding:0!important}}.Ssw__Inner[data-v-0f8bf23c]{border-left:2px solid #000;border-right:2px solid #000;padding-bottom:7rem}@media(max-width:960px){.Ssw__Inner[data-v-0f8bf23c]{padding:2.5rem 0 5rem}}.Ssw__Background[data-v-0f8bf23c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat;bottom:0;left:0;pointer-events:none;position:absolute;right:0;top:0;z-index:0}.SswHeader[data-v-0f8bf23c]{align-items:center;border:2px solid #000!important;box-shadow:none!important;display:flex;height:auto!important;height:6.25rem!important;padding:0 2.5rem;width:100%}@media(max-width:960px){.SswHeader[data-v-0f8bf23c]{height:4.375rem!important;padding:0!important}}.SswHeader__logo[data-v-0f8bf23c]{display:block;width:13.75rem}@media(max-width:960px){.SswHeader__logo[data-v-0f8bf23c]{width:8.5rem}}.SswHeader__logo img[data-v-0f8bf23c]{display:block;height:100%;object-fit:contain;width:100%}.SswHeader__inner[data-v-0f8bf23c]{justify-content:space-between}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
