const appid =
  process.env.FIRE_ENV === "production"
    ? "AIzaSyA4bUqwaocBRHi29t97n11XPghN_77HL9M"
    : "AIzaSyCAh8dWeM7cQwUItt0bkBHlq96Op8RvtPs";

const gmapConfig = {
  appid,
  staticMapBase: `https://maps.googleapis.com/maps/api/staticmap?key=${appid}&`,
};

const langCode = (locale: string) => {
  if (locale === "ja") {
    return "ja";
  }
  if (locale === "zh-Hans") {
    return "zh-CN";
  }
  return "en";
};

export { gmapConfig, langCode };
