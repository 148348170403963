import { GetterTree, ActionTree, MutationTree } from "vuex";
import { Loader } from "google-maps";
import { gmapConfig } from "@/gmap";
import { RootState } from "~/store";

export const state = () => ({
  googleMapsLoaded: false,
});

export type MapsState = ReturnType<typeof state>;

export const mutations: MutationTree<MapsState> = {
  setGoogleMapsLoaded(state, value: boolean) {
    state.googleMapsLoaded = value;
  },
};

export const actions: ActionTree<MapsState, RootState> = {
  async loadGoogleMaps({ state, commit }, options) {
    if (!state.googleMapsLoaded) {
      const loader = new Loader(gmapConfig.appid, options);
      await loader.load();
      commit("setGoogleMapsLoaded", true);
    }
  },
};

export const getters: GetterTree<MapsState, RootState> = {
  googleMapsLoaded(state) {
    return state.googleMapsLoaded;
  },
};
