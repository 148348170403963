import type { GetterTree, ActionTree, MutationTree } from "vuex";
import { customAlphabet } from "nanoid";
import { nameToCode } from "~/prefectures";
import type { RootState } from "~/store";

export const strict = false;

const tour: Peeeps.Data.Tour = {
  image: "",
  lang: "ja",
  title: "",
  title_en: "",
  title_ja: "",
  "title_zh-Hans": "",
  description: "",
  description_ja: "",
  description_en: "",
  "description_zh-Hans": "",
  category: undefined,
  estimated_time: 0,
  tags: [],
  tags_en: [],
  tags_ja: [],
  "tags_zh-Hans": [],
  spots: [],
  start: 0,
  end: Number.MAX_SAFE_INTEGER,
  draft: false,
  suspended: false,
  pref_codes: [],
  num_entried: 0,
  num_completed: 0,
  review: 0,
  num_reviews: 0,
  num_favorites: 0,
  credit: "",
};

const addresses: string[] = [];

export const state = () => ({
  tour,
  addresses,
});

export type TourState = ReturnType<typeof state>;

export const mutations: MutationTree<TourState> = {
  CLEAR(state) {
    state.tour = {
      image: "",
      lang: "ja",
      title: "",
      title_en: "",
      title_ja: "",
      "title_zh-Hans": "",
      description: "",
      description_ja: "",
      description_en: "",
      "description_zh-Hans": "",
      category: undefined,
      estimated_time: 0,
      tags: [],
      tags_en: [],
      tags_ja: [],
      "tags_zh-Hans": [],
      spots: [],
      start: 0,
      end: Number.MAX_SAFE_INTEGER,
      draft: false,
      suspended: false,
      pref_codes: [],
      num_entried: 0,
      num_completed: 0,
      review: 0,
      num_reviews: 0,
      num_favorites: 0,
      credit: "",
    };
    state.addresses = [];
  },
  UPDATE(state, payload) {
    state.tour = {
      ...state.tour,
      ...payload,
    };
  },
  UPDATE_ADDRESS(state, payload) {
    state.addresses = JSON.parse(JSON.stringify(payload));
  },
};

interface PostArgs {
  user: string;
  tagDocRefs: firebase.default.firestore.DocumentReference[];
  tagsEn: string[];
  tagsJa: string[];
  tagsZh: string[];
}

export const actions: ActionTree<TourState, RootState> = {
  async post({ commit, state }, arg: PostArgs) {
    const tour: Peeeps.Data.Tour = {
      image: state.tour.image,
      user: arg.user,
      lang: state.tour.lang,
      title: state.tour.title,
      title_en: state.tour.title_en,
      title_ja: state.tour.title_ja,
      "title_zh-Hans": state.tour["title_zh-Hans"],
      description: state.tour.description,
      description_ja: state.tour.description_ja,
      description_en: state.tour.description_en,
      "description_zh-Hans": state.tour["description_zh-Hans"],
      category: state.tour.category,
      estimated_time: state.tour.estimated_time || 0,
      tags: arg.tagDocRefs,
      tags_en: arg.tagsEn,
      tags_ja: arg.tagsJa,
      "tags_zh-Hans": arg.tagsZh,
      spots: state.tour.spots,
      start: state.tour.start || 0,
      end: state.tour.end || Number.MAX_SAFE_INTEGER,
      draft: false,
      suspended: false,
      pref_codes: [
        ...new Set<string>(
          state.tour.spots!.map((spot: Peeeps.Data.TourSpot) =>
            nameToCode(spot.location_ja!)
          )
        ),
      ],
      num_entried: 0,
      num_completed: 0,
      review: 0,
      num_reviews: 0,
      num_favorites: 0,
      credit: state.tour.credit,
      created_at: this.$fireModule.firestore.FieldValue.serverTimestamp(),
      updated_at: this.$fireModule.firestore.FieldValue.serverTimestamp(),
    };
    const result = await this.$fire.firestore
      .doc(`tours/${state.tour.id}`)
      .set(tour)
      .catch((err) => {
        err.tour = tour;
        this.$sentry.captureException(err);
        return false;
      });
    if (result !== false) {
      commit("CLEAR");
    }
    return result !== false;
  },
};

export const getters: GetterTree<TourState, RootState> = {
  input(state): Peeeps.Data.Tour {
    return state.tour;
  },
  tourID(state): string {
    if (!state.tour.id) {
      // ドキュメントIDの昇順で、作成順に並べるため、時間と乱数によるIDにする
      const tourID =
        (Number.MAX_SAFE_INTEGER - Date.now()).toString().padStart(16, "0") +
        "0" +
        customAlphabet(
          "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
          11
        )();
      state.tour.id = tourID;
    }
    return state.tour.id;
  },
  addresses(state): string[] {
    return JSON.parse(JSON.stringify(state.addresses));
  },
};
