import type { GetterTree, ActionTree, MutationTree } from "vuex";
import type { RootState } from "~/store";

export const strict = false;

export const state = () => ({
  uid: null,
  email: null,
  emailVerified: false,
  displayName: null,
  registered: false,
  isAdmin: false,
  isHost: false,
  loginUserAccount: "",
  loginUserName: "",
  loginUserAvatarURL: "",
});

export type UserState = ReturnType<typeof state>;

export const mutations: MutationTree<UserState> = {
  LOGIN(state, payload) {
    state.uid = payload.uid;
    state.email = payload.email;
    state.emailVerified = payload.emailVerified;
    state.displayName = payload.displayName;
    state.registered = payload.registered;
    state.isAdmin = payload.isAdmin;
    state.isHost = payload.isHost;
    state.loginUserAccount = payload.loginUserAccount;
    state.loginUserName = payload.loginUserName;
    state.loginUserAvatarURL = payload.loginUserAvatarURL;
  },
  LOGOUT(state) {
    state.uid = null;
    state.email = null;
    state.emailVerified = false;
    state.displayName = null;
    state.registered = false;
    state.isAdmin = false;
    state.isHost = false;
    state.loginUserAccount = "";
    state.loginUserName = "";
    state.loginUserAvatarURL = "";
  },
};

export const actions: ActionTree<UserState, RootState> = {
  async logout({ commit }) {
    await this.$fire.auth.signOut();
    commit("LOGOUT");
  },
  setUser({ commit }, payload) {
    commit("LOGIN", payload);
  },
};

export const getters: GetterTree<UserState, RootState> = {
  isAuthenticated(state): boolean {
    return !!state.uid;
  },
  isRegistered(state): boolean {
    return !!state.registered;
  },
  isAdmin(state): boolean {
    return !!state.isAdmin;
  },
  isHost(state): boolean {
    return !!state.isHost;
  },
  loginUid(state): string {
    return state.uid || "";
  },
  loginUser(state) {
    return {
      uid: state.uid,
      email: state.email,
      emailVerified: state.emailVerified,
      displayName: state.displayName,
      registered: state.registered,
      isAdmin: state.isAdmin,
      isHost: state.isHost,
      loginUserAccount: state.loginUserAccount,
      loginUserName: state.loginUserName,
      loginUserAvatarURL: state.loginUserAvatarURL,
    };
  },
  loginUserAccount(state): string {
    return state.loginUserAccount;
  },
  loginUserName(state): string {
    return state.loginUserName;
  },
  loginUserAvatarURL(state): string {
    return state.loginUserAvatarURL;
  },
};
