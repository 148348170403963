export const SswAbout = () => import('../../src/components/lp/setouchi-sazanami-workation/SswAbout.vue' /* webpackChunkName: "components/ssw-about" */).then(c => wrapFunctional(c.default || c))
export const SswAccess = () => import('../../src/components/lp/setouchi-sazanami-workation/SswAccess.vue' /* webpackChunkName: "components/ssw-access" */).then(c => wrapFunctional(c.default || c))
export const SswBanner = () => import('../../src/components/lp/setouchi-sazanami-workation/SswBanner.vue' /* webpackChunkName: "components/ssw-banner" */).then(c => wrapFunctional(c.default || c))
export const SswCredit = () => import('../../src/components/lp/setouchi-sazanami-workation/SswCredit.vue' /* webpackChunkName: "components/ssw-credit" */).then(c => wrapFunctional(c.default || c))
export const SswFirstview = () => import('../../src/components/lp/setouchi-sazanami-workation/SswFirstview.vue' /* webpackChunkName: "components/ssw-firstview" */).then(c => wrapFunctional(c.default || c))
export const SswLinks = () => import('../../src/components/lp/setouchi-sazanami-workation/SswLinks.vue' /* webpackChunkName: "components/ssw-links" */).then(c => wrapFunctional(c.default || c))
export const SswModelCourse = () => import('../../src/components/lp/setouchi-sazanami-workation/SswModelCourse.vue' /* webpackChunkName: "components/ssw-model-course" */).then(c => wrapFunctional(c.default || c))
export const SswSpot = () => import('../../src/components/lp/setouchi-sazanami-workation/SswSpot.vue' /* webpackChunkName: "components/ssw-spot" */).then(c => wrapFunctional(c.default || c))
export const OverLoading = () => import('../../src/components/atoms/OverLoading.vue' /* webpackChunkName: "components/over-loading" */).then(c => wrapFunctional(c.default || c))
export const ReviewStars = () => import('../../src/components/atoms/ReviewStars.vue' /* webpackChunkName: "components/review-stars" */).then(c => wrapFunctional(c.default || c))
export const Reviewer = () => import('../../src/components/atoms/Reviewer.vue' /* webpackChunkName: "components/reviewer" */).then(c => wrapFunctional(c.default || c))
export const UserAvatar = () => import('../../src/components/atoms/UserAvatar.vue' /* webpackChunkName: "components/user-avatar" */).then(c => wrapFunctional(c.default || c))
export const AboutCard = () => import('../../src/components/molecules/AboutCard.vue' /* webpackChunkName: "components/about-card" */).then(c => wrapFunctional(c.default || c))
export const BannerItem = () => import('../../src/components/molecules/BannerItem.vue' /* webpackChunkName: "components/banner-item" */).then(c => wrapFunctional(c.default || c))
export const BottomNavigation = () => import('../../src/components/molecules/BottomNavigation.vue' /* webpackChunkName: "components/bottom-navigation" */).then(c => wrapFunctional(c.default || c))
export const EntryButton = () => import('../../src/components/molecules/EntryButton.vue' /* webpackChunkName: "components/entry-button" */).then(c => wrapFunctional(c.default || c))
export const HostBanner = () => import('../../src/components/molecules/HostBanner.vue' /* webpackChunkName: "components/host-banner" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../src/components/molecules/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const PButton = () => import('../../src/components/molecules/PButton.vue' /* webpackChunkName: "components/p-button" */).then(c => wrapFunctional(c.default || c))
export const ReviewCard = () => import('../../src/components/molecules/ReviewCard.vue' /* webpackChunkName: "components/review-card" */).then(c => wrapFunctional(c.default || c))
export const ScrollBarInfo = () => import('../../src/components/molecules/ScrollBarInfo.vue' /* webpackChunkName: "components/scroll-bar-info" */).then(c => wrapFunctional(c.default || c))
export const SpotButton = () => import('../../src/components/molecules/SpotButton.vue' /* webpackChunkName: "components/spot-button" */).then(c => wrapFunctional(c.default || c))
export const SpotCardSkelton = () => import('../../src/components/molecules/SpotCardSkelton.vue' /* webpackChunkName: "components/spot-card-skelton" */).then(c => wrapFunctional(c.default || c))
export const SpotDescription = () => import('../../src/components/molecules/SpotDescription.vue' /* webpackChunkName: "components/spot-description" */).then(c => wrapFunctional(c.default || c))
export const TagItem = () => import('../../src/components/molecules/TagItem.vue' /* webpackChunkName: "components/tag-item" */).then(c => wrapFunctional(c.default || c))
export const TourCard = () => import('../../src/components/molecules/TourCard.vue' /* webpackChunkName: "components/tour-card" */).then(c => wrapFunctional(c.default || c))
export const TourCardSkelton = () => import('../../src/components/molecules/TourCardSkelton.vue' /* webpackChunkName: "components/tour-card-skelton" */).then(c => wrapFunctional(c.default || c))
export const TourCreator = () => import('../../src/components/molecules/TourCreator.vue' /* webpackChunkName: "components/tour-creator" */).then(c => wrapFunctional(c.default || c))
export const TourDescription = () => import('../../src/components/molecules/TourDescription.vue' /* webpackChunkName: "components/tour-description" */).then(c => wrapFunctional(c.default || c))
export const TourDirectShare = () => import('../../src/components/molecules/TourDirectShare.vue' /* webpackChunkName: "components/tour-direct-share" */).then(c => wrapFunctional(c.default || c))
export const TourEntrySupplText = () => import('../../src/components/molecules/TourEntrySupplText.vue' /* webpackChunkName: "components/tour-entry-suppl-text" */).then(c => wrapFunctional(c.default || c))
export const TourItemSkelton = () => import('../../src/components/molecules/TourItemSkelton.vue' /* webpackChunkName: "components/tour-item-skelton" */).then(c => wrapFunctional(c.default || c))
export const TourKeyVisualFooter = () => import('../../src/components/molecules/TourKeyVisualFooter.vue' /* webpackChunkName: "components/tour-key-visual-footer" */).then(c => wrapFunctional(c.default || c))
export const Workations = () => import('../../src/components/molecules/Workations.vue' /* webpackChunkName: "components/workations" */).then(c => wrapFunctional(c.default || c))
export const AvatarUpdater = () => import('../../src/components/organisms/AvatarUpdater.vue' /* webpackChunkName: "components/avatar-updater" */).then(c => wrapFunctional(c.default || c))
export const CategoryList = () => import('../../src/components/organisms/CategoryList.vue' /* webpackChunkName: "components/category-list" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../src/components/organisms/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const ImageUpdater = () => import('../../src/components/organisms/ImageUpdater.vue' /* webpackChunkName: "components/image-updater" */).then(c => wrapFunctional(c.default || c))
export const MapModal = () => import('../../src/components/organisms/MapModal.vue' /* webpackChunkName: "components/map-modal" */).then(c => wrapFunctional(c.default || c))
export const PeeepsFooter = () => import('../../src/components/organisms/PeeepsFooter.vue' /* webpackChunkName: "components/peeeps-footer" */).then(c => wrapFunctional(c.default || c))
export const ReleaseTourModal = () => import('../../src/components/organisms/ReleaseTourModal.vue' /* webpackChunkName: "components/release-tour-modal" */).then(c => wrapFunctional(c.default || c))
export const ReviewerFetcher = () => import('../../src/components/organisms/ReviewerFetcher.vue' /* webpackChunkName: "components/reviewer-fetcher" */).then(c => wrapFunctional(c.default || c))
export const SearchModal = () => import('../../src/components/organisms/SearchModal.vue' /* webpackChunkName: "components/search-modal" */).then(c => wrapFunctional(c.default || c))
export const ShareModal = () => import('../../src/components/organisms/ShareModal.vue' /* webpackChunkName: "components/share-modal" */).then(c => wrapFunctional(c.default || c))
export const SignIn = () => import('../../src/components/organisms/SignIn.vue' /* webpackChunkName: "components/sign-in" */).then(c => wrapFunctional(c.default || c))
export const SizeGuideModal = () => import('../../src/components/organisms/SizeGuideModal.vue' /* webpackChunkName: "components/size-guide-modal" */).then(c => wrapFunctional(c.default || c))
export const SpotDetail = () => import('../../src/components/organisms/SpotDetail.vue' /* webpackChunkName: "components/spot-detail" */).then(c => wrapFunctional(c.default || c))
export const SpotEditModal = () => import('../../src/components/organisms/SpotEditModal.vue' /* webpackChunkName: "components/spot-edit-modal" */).then(c => wrapFunctional(c.default || c))
export const SpotInput = () => import('../../src/components/organisms/SpotInput.vue' /* webpackChunkName: "components/spot-input" */).then(c => wrapFunctional(c.default || c))
export const SpotKeyVisual = () => import('../../src/components/organisms/SpotKeyVisual.vue' /* webpackChunkName: "components/spot-key-visual" */).then(c => wrapFunctional(c.default || c))
export const TagInput = () => import('../../src/components/organisms/TagInput.vue' /* webpackChunkName: "components/tag-input" */).then(c => wrapFunctional(c.default || c))
export const TagItems = () => import('../../src/components/organisms/TagItems.vue' /* webpackChunkName: "components/tag-items" */).then(c => wrapFunctional(c.default || c))
export const TopBannerList = () => import('../../src/components/organisms/TopBannerList.vue' /* webpackChunkName: "components/top-banner-list" */).then(c => wrapFunctional(c.default || c))
export const TopMainVisual = () => import('../../src/components/organisms/TopMainVisual.vue' /* webpackChunkName: "components/top-main-visual" */).then(c => wrapFunctional(c.default || c))
export const TourCardList = () => import('../../src/components/organisms/TourCardList.vue' /* webpackChunkName: "components/tour-card-list" */).then(c => wrapFunctional(c.default || c))
export const TourEntry = () => import('../../src/components/organisms/TourEntry.vue' /* webpackChunkName: "components/tour-entry" */).then(c => wrapFunctional(c.default || c))
export const TourFetcher = () => import('../../src/components/organisms/TourFetcher.vue' /* webpackChunkName: "components/tour-fetcher" */).then(c => wrapFunctional(c.default || c))
export const TourItemList = () => import('../../src/components/organisms/TourItemList.vue' /* webpackChunkName: "components/tour-item-list" */).then(c => wrapFunctional(c.default || c))
export const TourKeyVisual = () => import('../../src/components/organisms/TourKeyVisual.vue' /* webpackChunkName: "components/tour-key-visual" */).then(c => wrapFunctional(c.default || c))
export const TourKeyVisualInner = () => import('../../src/components/organisms/TourKeyVisualInner.vue' /* webpackChunkName: "components/tour-key-visual-inner" */).then(c => wrapFunctional(c.default || c))
export const TourMap = () => import('../../src/components/organisms/TourMap.vue' /* webpackChunkName: "components/tour-map" */).then(c => wrapFunctional(c.default || c))
export const TourRecommend = () => import('../../src/components/organisms/TourRecommend.vue' /* webpackChunkName: "components/tour-recommend" */).then(c => wrapFunctional(c.default || c))
export const TourReview = () => import('../../src/components/organisms/TourReview.vue' /* webpackChunkName: "components/tour-review" */).then(c => wrapFunctional(c.default || c))
export const TourSpotList = () => import('../../src/components/organisms/TourSpotList.vue' /* webpackChunkName: "components/tour-spot-list" */).then(c => wrapFunctional(c.default || c))
export const FukuokaAbout = () => import('../../src/components/lp/fukuoka-hotel-ryokan/FukuokaAbout.vue' /* webpackChunkName: "components/fukuoka-about" */).then(c => wrapFunctional(c.default || c))
export const FukuokaAccess = () => import('../../src/components/lp/fukuoka-hotel-ryokan/FukuokaAccess.vue' /* webpackChunkName: "components/fukuoka-access" */).then(c => wrapFunctional(c.default || c))
export const FukuokaCredit = () => import('../../src/components/lp/fukuoka-hotel-ryokan/FukuokaCredit.vue' /* webpackChunkName: "components/fukuoka-credit" */).then(c => wrapFunctional(c.default || c))
export const FukuokaFirstview = () => import('../../src/components/lp/fukuoka-hotel-ryokan/FukuokaFirstview.vue' /* webpackChunkName: "components/fukuoka-firstview" */).then(c => wrapFunctional(c.default || c))
export const FukuokaGuideBook = () => import('../../src/components/lp/fukuoka-hotel-ryokan/FukuokaGuideBook.vue' /* webpackChunkName: "components/fukuoka-guide-book" */).then(c => wrapFunctional(c.default || c))
export const FukuokaInfo = () => import('../../src/components/lp/fukuoka-hotel-ryokan/FukuokaInfo.vue' /* webpackChunkName: "components/fukuoka-info" */).then(c => wrapFunctional(c.default || c))
export const FukuokaLoading = () => import('../../src/components/lp/fukuoka-hotel-ryokan/FukuokaLoading.vue' /* webpackChunkName: "components/fukuoka-loading" */).then(c => wrapFunctional(c.default || c))
export const ChildMarker = () => import('../../src/components/ChildMarker.vue' /* webpackChunkName: "components/child-marker" */).then(c => wrapFunctional(c.default || c))
export const MapLoader = () => import('../../src/components/MapLoader.vue' /* webpackChunkName: "components/map-loader" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
