
export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500;
    },
  },
  mounted() {
    if (this.error && this.error.statusCode !== 404) {
      this.$sentry.captureException(this.error);
    }
  },
};
