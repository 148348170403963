export default ({ app }) => {
  app.i18n.onLanguageSwitched = (_, newLocale) => {
    if (newLocale === "en") {
      return;
    }
    if (newLocale === "ja" && app.head && Array.isArray(app.head.link)) {
      if (
        !app.head.link.some(
          (li) =>
            li.rel &&
            li.href &&
            li.rel === "stylesheet" &&
            li.href ===
              "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap"
        )
      ) {
        app.head.link.push({
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap",
        });
      }
      if (
        !app.head.link.some(
          (li) =>
            li.rel &&
            li.href &&
            li.rel === "stylesheet" &&
            li.href ===
              "https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/css/yakuhanjp.min.css"
        )
      ) {
        app.head.link.push({
          rel: "stylesheet",
          href: "https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/css/yakuhanjp.min.css",
        });
      }
    } else if (
      newLocale === "zh-Hans" &&
      app.head &&
      Array.isArray(app.head.link) &&
      !app.head.link.some(
        (li) =>
          li.rel &&
          li.href &&
          li.rel === "stylesheet" &&
          li.href ===
            "https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700;900&display=swap"
      )
    ) {
      app.head.link.push({
        rel: "stylesheet",
        href: "https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700;900&display=swap",
      });
    }
  };
};
