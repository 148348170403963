const isStaging = process.env.FIRE_ENV !== "production";
const isProduction = !isStaging;

const productionConf = {
  apiKey: "AIzaSyD_4tOIPjNeTzAQP8ffEWNAAnZEKz36ePA",
  authDomain: "spot.peeeps.app",
  databaseURL: "https://peeeps-72f2f.firebaseio.com",
  projectId: "peeeps-72f2f",
  storageBucket: "peeeps-72f2f",
  messagingSenderId: "527794551595",
  appId: "1:527794551595:web:96f6045863385371",
  measurementId: "G-64XDMH7GSG",
};

const stagingConf = {
  apiKey: "AIzaSyBV59Kj4IbHYoc3B1cB9g7QGdYMjIYonkA",
  authDomain: "web-spot-staging-c6172.web.app",
  databaseURL: "https://diggy-staging.firebaseio.com",
  projectId: "diggy-staging",
  storageBucket: "diggy-staging",
  messagingSenderId: "1009269114628",
  appId: "1:1009269114628:web:9cb4e599c2279b45953251",
  measurementId: "G-QHYWQEXHJJ",
};

const questionStorageBucket = {
  production: "peeeps-72f2f.appspot.com",
  staging: "diggy-staging.appspot.com",
};

const getFireConf = () => {
  if (isProduction) {
    return productionConf;
  }
  return stagingConf;
};

const getQuestionStorageBucket = () => {
  if (isProduction) {
    return questionStorageBucket.production;
  }
  return questionStorageBucket.staging;
};

const originURL = `https://${getFireConf().authDomain}`;

const apiBasePath = `https://us-central1-${
  getFireConf().projectId
}.cloudfunctions.net/api`;

export {
  isStaging,
  isProduction,
  originURL,
  productionConf,
  stagingConf,
  getFireConf,
  questionStorageBucket,
  getQuestionStorageBucket,
  apiBasePath,
};
