// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@5.2.7_webpack@4.46.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@5.2.7_webpack@4.46.0/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/logo.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header[data-v-2d8bf946]{align-items:center;background-color:#fff;box-shadow:none!important;display:flex;height:auto!important;justify-content:space-between;padding:1.25rem 2rem;width:100%}@media(max-width:960px){.Header[data-v-2d8bf946]{padding:1rem}}.Header__Logo[data-v-2d8bf946]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 50%;background-size:100% auto;height:2.75rem;text-indent:-9999px;width:12.5rem}@media(max-width:960px){.Header__Logo[data-v-2d8bf946]{height:1.75rem;width:7.75rem}}.Header__Buttons[data-v-2d8bf946],.Header__List[data-v-2d8bf946]{align-items:center;display:flex}.Header__Buttons[data-v-2d8bf946]{gap:.625rem}@media(max-width:960px){.Header__Buttons[data-v-2d8bf946]{display:none}}.Header__Buttons .v-btn[data-v-2d8bf946]{border-radius:.5rem!important;height:2.75rem!important;width:12.5rem!important}.Header__Buttons .v-btn[data-v-2d8bf946] .v-btn__content{align-items:center!important;display:flex!important}.Header__Buttons .v-btn[data-v-2d8bf946] .v-btn__content{gap:.25rem}.Header__Auth[data-v-2d8bf946]{margin-left:1rem}.Header .InnerText[data-v-2d8bf946]{line-height:1}@media(max-width:960px){.Header .UserAvatar[data-v-2d8bf946]{height:2.5rem!important;min-width:0!important;min-width:auto!important;width:2.5rem!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
