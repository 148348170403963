import type { Plugin } from "@nuxt/types";
import { extend } from "vee-validate";

const veeValidatePlugin: Plugin = ({ app }) => {
  extend("unique_account", {
    message: (field) => "This " + field + " is already used",
    validate: async (value) => {
      if (!value) {
        return false;
      }
      const accountDoc = await app.$fire.firestore
        .doc(`accounts/${value}`)
        .get()
        .catch((err) => {
          app.$sentry.captureException(err);
        });
      if (accountDoc && accountDoc.exists) {
        // すでにアカウントが存在する
        return false;
      }
      return true;
    },
  });

  extend("title_row", {
    message: (field) => field + "は3行以内で入力してください",
    validate: (value) => {
      const rows = value.split(/\n/);
      if (rows.length > 3) {
        return false;
      }
      return true;
    },
  });
};

export default veeValidatePlugin;
