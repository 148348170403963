import type { GetterTree, ActionTree, MutationTree } from "vuex";
import type { RootState } from "~/store";

export const strict = false;

export const state = () => ({
  isShareActive: false,
  isSearchActive: false,
  isReleaseTourActive: false,
  share: {
    tourURL: "",
    tourTitle: "",
  },
});

export type ModalState = ReturnType<typeof state>;

export const mutations: MutationTree<ModalState> = {
  ACTIVE_SHARE(state, payload) {
    state.isShareActive = true;
    state.share = payload;
  },
  DEACTIVE_SHARE(state) {
    state.isShareActive = false;
    state.share = {
      tourURL: "",
      tourTitle: "",
    };
  },
  ACTIVE_SEARCH(state) {
    state.isSearchActive = true;
  },
  DEACTIVE_SEARCH(state) {
    state.isSearchActive = false;
  },
  ACTIVE_RELEASE_TOUR(state) {
    state.isReleaseTourActive = true;
  },
  DEACTIVE_RELEASE_TOUR(state) {
    state.isReleaseTourActive = false;
  },
};

export const actions: ActionTree<ModalState, RootState> = {
  openShareModal({ commit }, payload) {
    commit("ACTIVE_SHARE", payload);
  },
  closeShareModal({ commit }) {
    commit("DEACTIVE_SHARE");
  },
  openSearchModal({ commit }) {
    commit("ACTIVE_SEARCH");
  },
  closeSearchModal({ commit }) {
    commit("DEACTIVE_SEARCH");
  },
  openReleaseTourModal({ commit }) {
    commit("ACTIVE_RELEASE_TOUR");
  },
  closeReleaseTourModal({ commit }) {
    commit("DEACTIVE_RELEASE_TOUR");
  },
};

export const getters: GetterTree<ModalState, RootState> = {
  isShareActive(state) {
    return state.isShareActive;
  },
  isSearchActive(state) {
    return state.isSearchActive;
  },
  isReleaseTourActive(state) {
    return state.isReleaseTourActive;
  },
  shareTourURL(state) {
    return state.share.tourURL;
  },
  shareTourTitle(state) {
    return state.share.tourTitle;
  },
};
