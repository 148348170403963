export default async function (session) {
  // Can only be initiated on client side
  if (!process.client) {
    return
  }

  await import('firebase/remote-config')

  const remoteConfigService = session.remoteConfig()

  remoteConfigService.settings = {
    fetchTimeoutMillis: 30000,
    minimumFetchIntervalMillis: 600000
  }

  return remoteConfigService
}
