const middleware = {}

middleware['admin'] = require('../src/middleware/admin.ts')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['authenticated'] = require('../src/middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['registered'] = require('../src/middleware/registered.ts')
middleware['registered'] = middleware['registered'].default || middleware['registered']

middleware['trailing-slash'] = require('../src/middleware/trailing-slash.ts')
middleware['trailing-slash'] = middleware['trailing-slash'].default || middleware['trailing-slash']

export default middleware
