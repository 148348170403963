import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=2d8bf946&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"
import style0 from "./Header.vue?vue&type=style&index=0&id=2d8bf946&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d8bf946",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAvatar: require('/home/runner/work/peeeps-spot/peeeps-spot/src/components/atoms/UserAvatar.vue').default,Header: require('/home/runner/work/peeeps-spot/peeeps-spot/src/components/organisms/Header.vue').default})
