export default () => {
  if (!process.client) {
    return;
  }
  // @see https://github.com/Faisal-Manzer/postcss-viewport-height-correction
  const customViewportCorrectionVariable = "vh";
  function setViewportProperty(doc) {
    let prevClientHeight;
    const customVar = "--" + (customViewportCorrectionVariable || "vh");
    function handleResize() {
      const clientHeight = doc.clientHeight;
      if (clientHeight === prevClientHeight) return;
      requestAnimationFrame(function updateViewportHeight() {
        doc.style.setProperty(customVar, clientHeight * 0.01 + "px");
        prevClientHeight = clientHeight;
      });
    }
    handleResize();
    return handleResize;
  }
  window.addEventListener(
    "resize",
    setViewportProperty(document.documentElement)
  );
};
