
import Vue from "vue";

export default Vue.extend({
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    mid: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    handler: {
      type: Object,
      default: null,
    },
  },
  computed: {
    size(): number {
      if (this.large) {
        return 120;
      }
      if (this.mid) {
        return 64;
      }
      return 48;
    },
    imgSrc(): string {
      if (this.src) {
        return this.src;
      }
      return require("~/assets/images/icon/dummy_user.png");
    },
  },
});
