import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e5da67e4 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6d5af149 = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _18bc8552 = () => interopDefault(import('../src/pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _c5aee996 = () => interopDefault(import('../src/pages/help.vue' /* webpackChunkName: "pages/help" */))
const _8b5db3c8 = () => interopDefault(import('../src/pages/host.vue' /* webpackChunkName: "pages/host" */))
const _dfed19d8 = () => interopDefault(import('../src/pages/lists/index.vue' /* webpackChunkName: "pages/lists/index" */))
const _1dbd11a3 = () => interopDefault(import('../src/pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _47e6ba78 = () => interopDefault(import('../src/pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _311cf81e = () => interopDefault(import('../src/pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _07f04b32 = () => interopDefault(import('../src/pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _37108a43 = () => interopDefault(import('../src/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _f2dd077a = () => interopDefault(import('../src/pages/admin/tag-create.vue' /* webpackChunkName: "pages/admin/tag-create" */))
const _61a6a50d = () => interopDefault(import('../src/pages/admin/tour-create.vue' /* webpackChunkName: "pages/admin/tour-create" */))
const _169d93c8 = () => interopDefault(import('../src/pages/my/completed.vue' /* webpackChunkName: "pages/my/completed" */))
const _c54d6ed0 = () => interopDefault(import('../src/pages/my/edit-email.vue' /* webpackChunkName: "pages/my/edit-email" */))
const _12d65d6e = () => interopDefault(import('../src/pages/my/edit.vue' /* webpackChunkName: "pages/my/edit" */))
const _1d221392 = () => interopDefault(import('../src/pages/my/entried.vue' /* webpackChunkName: "pages/my/entried" */))
const _939277b0 = () => interopDefault(import('../src/pages/my/favorites.vue' /* webpackChunkName: "pages/my/favorites" */))
const _38dd42ef = () => interopDefault(import('../src/pages/my/tours/index.vue' /* webpackChunkName: "pages/my/tours/index" */))
const _10745ad7 = () => interopDefault(import('../src/pages/special/fukuoka-hotel-ryokan/index.vue' /* webpackChunkName: "pages/special/fukuoka-hotel-ryokan/index" */))
const _9b8ad716 = () => interopDefault(import('../src/pages/special/setouchi-sazanami-workation/index.vue' /* webpackChunkName: "pages/special/setouchi-sazanami-workation/index" */))
const _3143467d = () => interopDefault(import('../src/pages/my/tours/edit.vue' /* webpackChunkName: "pages/my/tours/edit" */))
const _ce6e87ba = () => interopDefault(import('../src/pages/my/email-certify/_id.vue' /* webpackChunkName: "pages/my/email-certify/_id" */))
const _37219a57 = () => interopDefault(import('../src/pages/my/tours/_tour/index.vue' /* webpackChunkName: "pages/my/tours/_tour/index" */))
const _323b3bd6 = () => interopDefault(import('../src/pages/my/tours/_tour/edit.vue' /* webpackChunkName: "pages/my/tours/_tour/edit" */))
const _32740c42 = () => interopDefault(import('../src/pages/tours/_tour/index.vue' /* webpackChunkName: "pages/tours/_tour/index" */))
const _23bdcce2 = () => interopDefault(import('../src/pages/users/_user/index.vue' /* webpackChunkName: "pages/users/_user/index" */))
const _0a5f145e = () => interopDefault(import('../src/pages/tours/_tour/_spot.vue' /* webpackChunkName: "pages/tours/_tour/_spot" */))
const _4ad4a121 = () => interopDefault(import('../src/pages/lists/_.vue' /* webpackChunkName: "pages/lists/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en/",
    component: _e5da67e4,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/ja/",
    component: _e5da67e4,
    pathToRegexpOptions: {"strict":true},
    name: "index___ja"
  }, {
    path: "/zh-Hans/",
    component: _e5da67e4,
    pathToRegexpOptions: {"strict":true},
    name: "index___zh-Hans"
  }, {
    path: "/en/about/",
    component: _6d5af149,
    pathToRegexpOptions: {"strict":true},
    name: "about___en"
  }, {
    path: "/en/faq/",
    component: _18bc8552,
    pathToRegexpOptions: {"strict":true},
    name: "faq___en"
  }, {
    path: "/en/help/",
    component: _c5aee996,
    pathToRegexpOptions: {"strict":true},
    name: "help___en"
  }, {
    path: "/en/host/",
    component: _8b5db3c8,
    pathToRegexpOptions: {"strict":true},
    name: "host___en"
  }, {
    path: "/en/lists/",
    component: _dfed19d8,
    pathToRegexpOptions: {"strict":true},
    name: "lists___en"
  }, {
    path: "/en/my/",
    component: _1dbd11a3,
    pathToRegexpOptions: {"strict":true},
    name: "my___en"
  }, {
    path: "/en/privacy/",
    component: _47e6ba78,
    pathToRegexpOptions: {"strict":true},
    name: "privacy___en"
  }, {
    path: "/en/sign-in/",
    component: _311cf81e,
    pathToRegexpOptions: {"strict":true},
    name: "sign-in___en"
  }, {
    path: "/en/sign-up/",
    component: _07f04b32,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up___en"
  }, {
    path: "/en/terms/",
    component: _37108a43,
    pathToRegexpOptions: {"strict":true},
    name: "terms___en"
  }, {
    path: "/ja/about/",
    component: _6d5af149,
    pathToRegexpOptions: {"strict":true},
    name: "about___ja"
  }, {
    path: "/ja/faq/",
    component: _18bc8552,
    pathToRegexpOptions: {"strict":true},
    name: "faq___ja"
  }, {
    path: "/ja/help/",
    component: _c5aee996,
    pathToRegexpOptions: {"strict":true},
    name: "help___ja"
  }, {
    path: "/ja/host/",
    component: _8b5db3c8,
    pathToRegexpOptions: {"strict":true},
    name: "host___ja"
  }, {
    path: "/ja/lists/",
    component: _dfed19d8,
    pathToRegexpOptions: {"strict":true},
    name: "lists___ja"
  }, {
    path: "/ja/my/",
    component: _1dbd11a3,
    pathToRegexpOptions: {"strict":true},
    name: "my___ja"
  }, {
    path: "/ja/privacy/",
    component: _47e6ba78,
    pathToRegexpOptions: {"strict":true},
    name: "privacy___ja"
  }, {
    path: "/ja/sign-in/",
    component: _311cf81e,
    pathToRegexpOptions: {"strict":true},
    name: "sign-in___ja"
  }, {
    path: "/ja/sign-up/",
    component: _07f04b32,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up___ja"
  }, {
    path: "/ja/terms/",
    component: _37108a43,
    pathToRegexpOptions: {"strict":true},
    name: "terms___ja"
  }, {
    path: "/zh-Hans/about/",
    component: _6d5af149,
    pathToRegexpOptions: {"strict":true},
    name: "about___zh-Hans"
  }, {
    path: "/zh-Hans/faq/",
    component: _18bc8552,
    pathToRegexpOptions: {"strict":true},
    name: "faq___zh-Hans"
  }, {
    path: "/zh-Hans/help/",
    component: _c5aee996,
    pathToRegexpOptions: {"strict":true},
    name: "help___zh-Hans"
  }, {
    path: "/zh-Hans/host/",
    component: _8b5db3c8,
    pathToRegexpOptions: {"strict":true},
    name: "host___zh-Hans"
  }, {
    path: "/zh-Hans/lists/",
    component: _dfed19d8,
    pathToRegexpOptions: {"strict":true},
    name: "lists___zh-Hans"
  }, {
    path: "/zh-Hans/my/",
    component: _1dbd11a3,
    pathToRegexpOptions: {"strict":true},
    name: "my___zh-Hans"
  }, {
    path: "/zh-Hans/privacy/",
    component: _47e6ba78,
    pathToRegexpOptions: {"strict":true},
    name: "privacy___zh-Hans"
  }, {
    path: "/zh-Hans/sign-in/",
    component: _311cf81e,
    pathToRegexpOptions: {"strict":true},
    name: "sign-in___zh-Hans"
  }, {
    path: "/zh-Hans/sign-up/",
    component: _07f04b32,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up___zh-Hans"
  }, {
    path: "/zh-Hans/terms/",
    component: _37108a43,
    pathToRegexpOptions: {"strict":true},
    name: "terms___zh-Hans"
  }, {
    path: "/en/admin/tag-create/",
    component: _f2dd077a,
    pathToRegexpOptions: {"strict":true},
    name: "admin-tag-create___en"
  }, {
    path: "/en/admin/tour-create/",
    component: _61a6a50d,
    pathToRegexpOptions: {"strict":true},
    name: "admin-tour-create___en"
  }, {
    path: "/en/my/completed/",
    component: _169d93c8,
    pathToRegexpOptions: {"strict":true},
    name: "my-completed___en"
  }, {
    path: "/en/my/edit-email/",
    component: _c54d6ed0,
    pathToRegexpOptions: {"strict":true},
    name: "my-edit-email___en"
  }, {
    path: "/en/my/edit/",
    component: _12d65d6e,
    pathToRegexpOptions: {"strict":true},
    name: "my-edit___en"
  }, {
    path: "/en/my/entried/",
    component: _1d221392,
    pathToRegexpOptions: {"strict":true},
    name: "my-entried___en"
  }, {
    path: "/en/my/favorites/",
    component: _939277b0,
    pathToRegexpOptions: {"strict":true},
    name: "my-favorites___en"
  }, {
    path: "/en/my/tours/",
    component: _38dd42ef,
    pathToRegexpOptions: {"strict":true},
    name: "my-tours___en"
  }, {
    path: "/en/special/fukuoka-hotel-ryokan/",
    component: _10745ad7,
    pathToRegexpOptions: {"strict":true},
    name: "special-fukuoka-hotel-ryokan___en"
  }, {
    path: "/en/special/setouchi-sazanami-workation/",
    component: _9b8ad716,
    pathToRegexpOptions: {"strict":true},
    name: "special-setouchi-sazanami-workation___en"
  }, {
    path: "/ja/admin/tag-create/",
    component: _f2dd077a,
    pathToRegexpOptions: {"strict":true},
    name: "admin-tag-create___ja"
  }, {
    path: "/ja/admin/tour-create/",
    component: _61a6a50d,
    pathToRegexpOptions: {"strict":true},
    name: "admin-tour-create___ja"
  }, {
    path: "/ja/my/completed/",
    component: _169d93c8,
    pathToRegexpOptions: {"strict":true},
    name: "my-completed___ja"
  }, {
    path: "/ja/my/edit-email/",
    component: _c54d6ed0,
    pathToRegexpOptions: {"strict":true},
    name: "my-edit-email___ja"
  }, {
    path: "/ja/my/edit/",
    component: _12d65d6e,
    pathToRegexpOptions: {"strict":true},
    name: "my-edit___ja"
  }, {
    path: "/ja/my/entried/",
    component: _1d221392,
    pathToRegexpOptions: {"strict":true},
    name: "my-entried___ja"
  }, {
    path: "/ja/my/favorites/",
    component: _939277b0,
    pathToRegexpOptions: {"strict":true},
    name: "my-favorites___ja"
  }, {
    path: "/ja/my/tours/",
    component: _38dd42ef,
    pathToRegexpOptions: {"strict":true},
    name: "my-tours___ja"
  }, {
    path: "/ja/special/fukuoka-hotel-ryokan/",
    component: _10745ad7,
    pathToRegexpOptions: {"strict":true},
    name: "special-fukuoka-hotel-ryokan___ja"
  }, {
    path: "/ja/special/setouchi-sazanami-workation/",
    component: _9b8ad716,
    pathToRegexpOptions: {"strict":true},
    name: "special-setouchi-sazanami-workation___ja"
  }, {
    path: "/zh-Hans/admin/tag-create/",
    component: _f2dd077a,
    pathToRegexpOptions: {"strict":true},
    name: "admin-tag-create___zh-Hans"
  }, {
    path: "/zh-Hans/admin/tour-create/",
    component: _61a6a50d,
    pathToRegexpOptions: {"strict":true},
    name: "admin-tour-create___zh-Hans"
  }, {
    path: "/zh-Hans/my/completed/",
    component: _169d93c8,
    pathToRegexpOptions: {"strict":true},
    name: "my-completed___zh-Hans"
  }, {
    path: "/zh-Hans/my/edit-email/",
    component: _c54d6ed0,
    pathToRegexpOptions: {"strict":true},
    name: "my-edit-email___zh-Hans"
  }, {
    path: "/zh-Hans/my/edit/",
    component: _12d65d6e,
    pathToRegexpOptions: {"strict":true},
    name: "my-edit___zh-Hans"
  }, {
    path: "/zh-Hans/my/entried/",
    component: _1d221392,
    pathToRegexpOptions: {"strict":true},
    name: "my-entried___zh-Hans"
  }, {
    path: "/zh-Hans/my/favorites/",
    component: _939277b0,
    pathToRegexpOptions: {"strict":true},
    name: "my-favorites___zh-Hans"
  }, {
    path: "/zh-Hans/my/tours/",
    component: _38dd42ef,
    pathToRegexpOptions: {"strict":true},
    name: "my-tours___zh-Hans"
  }, {
    path: "/zh-Hans/special/fukuoka-hotel-ryokan/",
    component: _10745ad7,
    pathToRegexpOptions: {"strict":true},
    name: "special-fukuoka-hotel-ryokan___zh-Hans"
  }, {
    path: "/zh-Hans/special/setouchi-sazanami-workation/",
    component: _9b8ad716,
    pathToRegexpOptions: {"strict":true},
    name: "special-setouchi-sazanami-workation___zh-Hans"
  }, {
    path: "/en/my/tours/edit/",
    component: _3143467d,
    pathToRegexpOptions: {"strict":true},
    name: "my-tours-edit___en"
  }, {
    path: "/ja/my/tours/edit/",
    component: _3143467d,
    pathToRegexpOptions: {"strict":true},
    name: "my-tours-edit___ja"
  }, {
    path: "/zh-Hans/my/tours/edit/",
    component: _3143467d,
    pathToRegexpOptions: {"strict":true},
    name: "my-tours-edit___zh-Hans"
  }, {
    path: "/en/my/email-certify/:id?/",
    component: _ce6e87ba,
    pathToRegexpOptions: {"strict":true},
    name: "my-email-certify-id___en"
  }, {
    path: "/en/my/tours/:tour/",
    component: _37219a57,
    pathToRegexpOptions: {"strict":true},
    name: "my-tours-tour___en"
  }, {
    path: "/ja/my/email-certify/:id?/",
    component: _ce6e87ba,
    pathToRegexpOptions: {"strict":true},
    name: "my-email-certify-id___ja"
  }, {
    path: "/ja/my/tours/:tour/",
    component: _37219a57,
    pathToRegexpOptions: {"strict":true},
    name: "my-tours-tour___ja"
  }, {
    path: "/zh-Hans/my/email-certify/:id?/",
    component: _ce6e87ba,
    pathToRegexpOptions: {"strict":true},
    name: "my-email-certify-id___zh-Hans"
  }, {
    path: "/zh-Hans/my/tours/:tour/",
    component: _37219a57,
    pathToRegexpOptions: {"strict":true},
    name: "my-tours-tour___zh-Hans"
  }, {
    path: "/en/my/tours/:tour/edit/",
    component: _323b3bd6,
    pathToRegexpOptions: {"strict":true},
    name: "my-tours-tour-edit___en"
  }, {
    path: "/ja/my/tours/:tour/edit/",
    component: _323b3bd6,
    pathToRegexpOptions: {"strict":true},
    name: "my-tours-tour-edit___ja"
  }, {
    path: "/zh-Hans/my/tours/:tour/edit/",
    component: _323b3bd6,
    pathToRegexpOptions: {"strict":true},
    name: "my-tours-tour-edit___zh-Hans"
  }, {
    path: "/en/tours/:tour/",
    component: _32740c42,
    pathToRegexpOptions: {"strict":true},
    name: "tours-tour___en"
  }, {
    path: "/en/users/:user/",
    component: _23bdcce2,
    pathToRegexpOptions: {"strict":true},
    name: "users-user___en"
  }, {
    path: "/ja/tours/:tour/",
    component: _32740c42,
    pathToRegexpOptions: {"strict":true},
    name: "tours-tour___ja"
  }, {
    path: "/ja/users/:user/",
    component: _23bdcce2,
    pathToRegexpOptions: {"strict":true},
    name: "users-user___ja"
  }, {
    path: "/zh-Hans/tours/:tour/",
    component: _32740c42,
    pathToRegexpOptions: {"strict":true},
    name: "tours-tour___zh-Hans"
  }, {
    path: "/zh-Hans/users/:user/",
    component: _23bdcce2,
    pathToRegexpOptions: {"strict":true},
    name: "users-user___zh-Hans"
  }, {
    path: "/en/tours/:tour?/:spot/",
    component: _0a5f145e,
    pathToRegexpOptions: {"strict":true},
    name: "tours-tour-spot___en"
  }, {
    path: "/ja/tours/:tour?/:spot/",
    component: _0a5f145e,
    pathToRegexpOptions: {"strict":true},
    name: "tours-tour-spot___ja"
  }, {
    path: "/zh-Hans/tours/:tour?/:spot/",
    component: _0a5f145e,
    pathToRegexpOptions: {"strict":true},
    name: "tours-tour-spot___zh-Hans"
  }, {
    path: "/en/lists/*/",
    component: _4ad4a121,
    pathToRegexpOptions: {"strict":true},
    name: "lists-all___en"
  }, {
    path: "/ja/lists/*/",
    component: _4ad4a121,
    pathToRegexpOptions: {"strict":true},
    name: "lists-all___ja"
  }, {
    path: "/zh-Hans/lists/*/",
    component: _4ad4a121,
    pathToRegexpOptions: {"strict":true},
    name: "lists-all___zh-Hans"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
