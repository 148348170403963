import type { Plugin } from "@nuxt/types";

const afterEachPlugin: Plugin = ({ app, store }) => {
  if (app.router) {
    app.router.afterEach((_, from) => {
      if (
        from.name &&
        from.name.startsWith("lists-all___") &&
        store.getters["modal/isSearchActive"]
      ) {
        store.commit("modal/DEACTIVE_SEARCH");
      }
    });
  }
};

export default afterEachPlugin;
