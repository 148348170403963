import type { Plugin } from "@nuxt/types";
import { apiBasePath } from "@/fireenv";

const authPlugin: Plugin = async ({ store, app }) => {
  if (process.static) {
    return;
  }
  if (process.server || store.getters["user/isAuthenticated"]) {
    return;
  }
  let unsubscribe: firebase.default.Unsubscribe | undefined;
  const authUser: firebase.default.User | null = await new Promise(
    (resolve) => {
      unsubscribe = app.$fire.auth.onAuthStateChanged((user) => resolve(user));
    }
  );
  if (authUser) {
    const { uid, email, emailVerified, displayName } = authUser;
    const usersDoc = await app.$fire.firestore
      .doc(`users/${uid}`)
      .get()
      .catch((err) => {
        app.$sentry.captureException(err);
      });
    let loginUser = {};
    if (usersDoc && usersDoc.exists) {
      loginUser = {
        loginUserName: usersDoc.get("name") || "",
        loginUserAccount: usersDoc.get("account") || "",
        loginUserAvatarURL: usersDoc.get("icon_image") || "",
        registered: !!usersDoc.get("spot_user"),
        isAdmin: !!usersDoc.get("spot_admin"),
        isHost: !!usersDoc.get("spot_host"),
      };
    }
    await store.dispatch("user/setUser", {
      uid,
      email,
      emailVerified,
      displayName,
      ...loginUser,
    });

    // カスタムクレーム付与
    try {
      const idToken = await authUser.getIdToken();
      app.$http.setToken(idToken, "Bearer");
      const res = await app.$http.post(`${apiBasePath}/setSpotClaims`);
      const result = await res.json();
      if (result && result.status === "success") {
        const newToken = await authUser.getIdToken(true);
        app.$http.setToken(newToken, "Bearer");
      }
    } catch (err) {
      app.$sentry.captureException(err);
    }
  } else {
    await store.dispatch("user/logout");
  }
  return Promise.resolve(unsubscribe ? unsubscribe() : undefined);
};

export default authPlugin;
